import { useRouteMatch } from 'react-router-dom';

import { getOsaPolicyService, useApiRequest } from '@eir/services';
import { Page, PageWrapper } from '@eir/letter';
import { PageContainer, PageFooter, PageHeader } from '../../components';
import Page1 from './components/Page1';
import Page2 from './components/Page2';
import Page3 from './components/Page3';

export const InsuranceLetterView = () => {
  const match = useRouteMatch('/p/:policyId');
  const { data, error } = useApiRequest(
    getOsaPolicyService('PetsonAPI', match.params.policyId)
  );

  if (error) console.log(error);

  let productName = '';

  switch (data?.answers && data.answers.product) {
    case 'petson-dog':
      productName = 'HUND';
      break;
    case 'petson-cat':
      productName = 'KATT';
      break;
    case 'petson-herd':
      productName = 'FLOCK';
      break;
    default:
      productName = '';
  }

  return (
    <>
      {data && (
        <PageWrapper>
          <Page
            pageHeader={<PageHeader />}
            pageFooter={<PageFooter />}
            content={
              <PageContainer>
                <Page1 data={data} productName={productName} />
              </PageContainer>
            }
          />

          <Page
            pageHeader={<PageHeader />}
            pageFooter={<PageFooter />}
            content={
              <PageContainer>
                <Page2 data={data} productName={productName} />
              </PageContainer>
            }
          />
          <Page
            pageHeader={<PageHeader />}
            pageFooter={<PageFooter />}
            content={
              <PageContainer>
                <Page3 data={data.paymentSchedule.schedule} />
              </PageContainer>
            }
          />
        </PageWrapper>
      )}
    </>
  );
};
