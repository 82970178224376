import { Page, IntroText, Table, TableData, TableRow } from './Page.styles';

const Page1 = ({ data, productName }) => {
  return (
    <Page>
      <h1>FÖRSÄKRINGSBREV - {productName}</h1>
      <div>
        <p>
          {data.answers.firstName} {data.answers.lastName}
        </p>
        <p>{data.answers.postalCode}</p>
      </div>
      <IntroText>
        <p>Välkommen till Petson!</p>
        <p>
          Detta är ditt försäkringsbrev för {data.answers.petName}. Läs igenom
          dina villkor och kontakta oss om du har frågor eller vill göra en
          förändring.
        </p>
      </IntroText>
      <section>
        <h3>Översikt</h3>
        <Table>
          <TableRow>
            <TableData>Försäkringsnummer</TableData>
            <TableData>{data.policyNumber}</TableData>
          </TableRow>
          <TableRow>
            <TableData>Avtalsperiod</TableData>
            <TableData>
              {data.answers.startDate} - {data.answers.endDate}
            </TableData>
          </TableRow>
          <TableRow>
            <TableData>Årsavgift</TableData>
            <TableData>
              {data.policyPremium.totalPremiumExcludingTax.toLocaleString(
                'sv-SE',
                {
                  maximumFractionDigits: 0,
                }
              )}{' '}
              SEK
            </TableData>
          </TableRow>
        </Table>
      </section>
      <section>
        <h3>Kontaktuppgifter till Petson</h3>
        <Table>
          <TableRow>
            <TableData>Telefon</TableData>
            <TableData>08 - 14 20 00</TableData>
          </TableRow>
          <TableRow>
            <TableData>E-post</TableData>
            <TableData>
              <a href='mailto:hej@petson.se'>hej@petson.se</a>
            </TableData>
          </TableRow>
          <TableRow>
            <TableData>Skadeanmälan</TableData>
            <TableData>
              <a href='mailto:skada@petson.se'>skada@petson.se</a>
            </TableData>
          </TableRow>
        </Table>
      </section>
    </Page>
  );
};

export default Page1;
