import { formatDate } from '@eir/utils';
import { Page, TableRow, Table, TableData, TableHeader } from './Page.styles';

const Page3 = ({ data }) => {
  return (
    <Page>
      <h3>Betalningsplan</h3>
      <Table>
        <TableRow>
          <TableHeader>Datum</TableHeader>
          <TableHeader>Belopp</TableHeader>
        </TableRow>
        {data.map((item, index) => (
          <TableRow key={index}>
            <TableData collected={item.collected}>
              {formatDate(item.periodStart)} - {formatDate(item.periodEnd)}
            </TableData>
            <TableData>
              {item.amount.toLocaleString('sv-SE', {
                maximumFractionDigits: 0,
              })}{' '}
              {item.collected === true && ' - Betalt'} SEK
            </TableData>
          </TableRow>
        ))}
      </Table>
    </Page>
  );
};

export default Page3;
