import { purchaseService } from '@eir/services';
import { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';

const PaymentView = () => {
  const match = useRouteMatch('/pay/:paymentProvider/:quoteId');
  const [error, setError] = useState(false);

  const redirectToPaymentProvider = async () => {
    try {
      const data = await purchaseService(
        'PetsonAPI',
        match.params.paymentProvider,
        match.params.quoteId
      );
      window.location.replace(data.redirectUrl);
    } catch (err) {
      setError(true);
      console.log(err);
    }
  };

  redirectToPaymentProvider();

  return <div></div>;
};

export default PaymentView;
