import { formatDate } from '@eir/utils';
import { PageHeaderContainer } from './PageHeader.styles';
import PetsonLogo from '../images/petson_logo_black.jpg';

const PageHeader = () => {
  return (
    <PageHeaderContainer>
      <img src={PetsonLogo} alt='Petson' />
      <span>Stockholm, {formatDate(new Date())}</span>
    </PageHeaderContainer>
  );
};

export default PageHeader;
