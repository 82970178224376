import styled from 'styled-components';

const PageFooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 0.8rem;
  padding: 0 3rem;

  @media print {
    padding: 10pt;
    width: 100%;
  }

  span {
    font-weight: 700;
  }
`;

const UrlContainer = styled.span`
  margin-left: auto;
  line-height: 0.7;
  color: #2a7aca;
`;

const LeftColumn = styled.div`
  width: 100%;
`;

const RightColumn = styled.div`
  text-align: right;
  width: 100%;
`;

export { PageFooterContainer, UrlContainer, LeftColumn, RightColumn };
