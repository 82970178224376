import { useRouteMatch } from 'react-router-dom';
import { reattachService } from '@eir/services';

const AttachView = () => {
  const match = useRouteMatch('/reattach/:paymentProvider/:ledgerId');

  const reattchLedger = async () => {
    try {
      const data = await reattachService(
        'PetsonAPI',
        match.params.paymentProvider,
        match.params.ledgerId
      );
      window.location.replace(data.redirectUrl);
    } catch (err) {}
  };

  reattchLedger();

  return <div></div>;
};

export default AttachView;
