import styled from 'styled-components';

const Container = styled.div`
  margin: 0 3rem;

  @media print {
    margin: 0 8mm;
  }
`;

export { Container };
