import { useEffect } from 'react';

const RedirectCatView = () => {
  useEffect(() => {
    window.location.href = 'https://www.petson.se/kattforsakring/teckna';
  }, []);

  return null;
};

export default RedirectCatView;
