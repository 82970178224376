import styled from 'styled-components';

const Page = styled.main`
  section {
    margin: 3rem 0;
  }

  h1,
  h3,
  h4 {
    color: #577c6b;
    margin-bottom: 0.25rem;
    font-family: 'BigCaslonBold';
  }

  h1 {
    margin-bottom: 1.125rem;
  }
`;

const Table = styled.table`
  display: grid;
  border: 0.063rem solid #a8d08d;
  table-layout: fixed;
  width: 100%;
  margin-top: 0.5rem;
  border-collapse: collapse;

  td,
  th {
    border-right: 0.063rem solid #a8d08d;
    border-collapse: collapse;
  }

  td:first-child {
    border-left: none;
  }

  td:last-child {
    border-right: none;
  }
  th:last-child {
    border-right: none;
  }
`;

const TableRow = styled.tr`
  display: flex;
  width: inherit;
  font-weight: 500;
  border-bottom: 0.063rem solid #a8d08d;
  align-items: center;

  &:nth-child(even) {
    background-color: #e2efd9;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }

  &:last-child {
    border-bottom: none;
  }

  p {
    font-size: 0.9rem;
    font-weight: 600;
    margin: auto 0;
  }
`;

const TableData = styled.td`
  font-size: 0.9rem;
  font-weight: 500;
  padding: 0.25rem;
  width: 50%;
`;

const TableHeader = styled.th`
  width: 50%;
  font-size: 0.9rem;
  font-weight: 600;
  padding: 0.25rem;
  text-align: left;
`;

const IntroText = styled.div`
  margin-top: 1.625rem;

  p {
    font-size: 1rem;
  }
`;

export { IntroText, Page, Table, TableData, TableHeader, TableRow };
