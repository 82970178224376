import styled from 'styled-components';

const PageHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;

  @media print {
    width: 100%;
  }

  img {
    width: 15rem;
    height: auto;
  }

  span {
    margin-left: auto;
  }
`;

export { PageHeaderContainer };
