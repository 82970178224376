import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
* {
  outline: none;
  box-sizing: border-box;
  margin: 0;
}

html {
  font-size: 16px;
  font-family: 'acumin-pro', sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}


h1, h2, h3 {
  font-family: 'big-caslon-fb';  

  @media print {
  }
}

h1 {
  margin-bottom: 1rem;

  @media print {
    font-size: 20pt;
    margin-bottom: 10pt;
  }
}

h2 {
  margin-bottom: 0.5rem;

  @media print {
    font-size: 12pt;
    margin-bottom: 2pt;
  }
}

p {
  font-size: 1.1rem;
  color: #262626;
  line-height: 1.3;
  margin-bottom: 0.5rem;

  @media print {
    font-size: 10pt;
    margin-bottom: 8pt;
  }
}

@page {
  size: A4;
  margin: 6.35mm 6.35mm 14.46mm 6.35mm !important;
  
} 
`;

export default GlobalStyle;
