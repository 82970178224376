import PropTypes from 'prop-types';

import { Container } from './PageContainer.styles';

const PageContainer = ({ children }) => {
  return <Container>{children}</Container>;
};

PageContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageContainer;
