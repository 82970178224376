import {
  PageFooterContainer,
  UrlContainer,
  LeftColumn,
  RightColumn,
} from './PageFooter.styles';

const PageFooter = () => {
  return (
    <PageFooterContainer>
      <LeftColumn>
        <span>Petson (Nostep AB)</span> <br /> Organisationsnummer: 559348-4222{' '}
        <br />
        Säte: Stockholm <br />
        Vendevägen 87, 182 32 Danderyd
      </LeftColumn>
      <RightColumn>
        <span>Kontakt</span> <br /> Telefon: 08 - 14 20 00 <br />
        Mejl:{' '}
        <UrlContainer>
          <a href='mailto:hej@petson.se'>hej@petson.se</a>
          <br /> <a href='www.petson.se'>www.petson.se</a>
        </UrlContainer>
      </RightColumn>
    </PageFooterContainer>
  );
};

export default PageFooter;
