import { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { configureAmplify } from '@eir/aws-authorization';
import GlobalStyle from './styles/globalStyle';
import { InsuranceLetterView } from './views/InsuranceLetter/InsuranceLetterView';

import AttachView from './views/Attach/AttachView';
import PaymentView from './views/Payment/PaymentView';
import RedirectDogView from './views/RedirectDog/RedirectDogView';
import RedirectCatView from './views/RedirectCat/RedirectCatView';

configureAmplify(
  {
    endpointName: 'PetsonAPI',
    endpointUrl: process.env.REACT_APP_AWS_ENDPOINT_URL,
  },
  true
);

const App = () => {
  const onResize = () => {
    window.parent.postMessage(
      {
        type: 'resize',
        height: document.body.scrollHeight,
      },
      '*'
    );
  };

  useEffect(() => {
    onResize();
    new ResizeObserver(_ => onResize()).observe(document.body);
  }, []);

  return (
    <Router>
      <Helmet>
        <link
          rel='stylesheet'
          type='text/css'
          href='https://use.typekit.net/kcb6hyf.css'
        />
      </Helmet>
      <GlobalStyle />
      <Switch>
        <Route exact path='/p/:policyId' component={InsuranceLetterView} />
        <Route exact path='/teckna/hund' component={RedirectDogView} />
        <Route exact path='/teckna/katt' component={RedirectCatView} />
        <Route
          exact
          path='/pay/:paymentProvider/:quoteId'
          component={PaymentView}
        />
        <Route
          exact
          path='/reattach/:paymentProvider/:ledgerId'
          component={AttachView}
        />
      </Switch>
    </Router>
  );
};

export default App;
